import React from 'react';
import { Container, Row } from 'emotion-flex';
import { css } from '@emotion/core';
import { ContentfulSocialLink } from '../../graphql-types';
import { Link } from 'gatsby';
import { ReactComponent as RightArrowIcon } from '../../assets/images/right-arrow.svg';
import hexToRgba from 'hex-to-rgba';

const styles = {
  block: css`
    min-height: 80px;
    min-width: 250px;
    border-style: solid;
    border-width: 1px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-items: center;
    margin: 13px;
    color: ${hexToRgba('#160B2C', 0.8)};
    text-transform: uppercase;
    &:hover {
      text-decoration: none;
    }
  `,
};

interface SocialLinkBlockProps {
  color: string;
  socialMediaName: string;
  url: string;
}

const SocialLinkBlock: React.FC<SocialLinkBlockProps> = ({
  color,
  socialMediaName,
  url,
}) => (
  <Link to={url} css={styles.block} style={{ borderColor: color }}>
    <div css={{ fontSize: 12 }}>
      <span>{socialMediaName}</span>
      <span css={{ paddingLeft: 10, position: 'relative' }}>
        <RightArrowIcon
          css={{ transform: `scale(0.8)`, position: 'absolute', top: 0.5 }}
        />
      </span>
    </div>
  </Link>
);

interface PressSocialMediaBlocksProps {
  items: ContentfulSocialLink[];
}

const PressSocialMediaBlocks: React.FC<PressSocialMediaBlocksProps> = ({
  items,
}) => {
  const colors = ['#F9C8D6', '#489F9D', '#9775C1'];
  return (
    <Container>
      <Row
        css={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {items.map(({ socialMediaName, url }, i) => (
          <SocialLinkBlock
            key={socialMediaName + url}
            color={colors[i % 3]} // colors[i%3] always choose a color from a colors list if there are more than 3 items in the future
            socialMediaName={socialMediaName}
            url={url}
          />
        ))}
      </Row>
    </Container>
  );
};

export default PressSocialMediaBlocks;
