import React from 'react';
import LinkWithArrow from './LinkWithArrow';
import hexToRgba from 'hex-to-rgba';
import { SYSTEM_FONTS } from '../../styles/typography';
import { ContentfulColorfulBoxes } from '../../graphql-types';

interface PerkCardWithLinkProps {
  background: string;
  item: ContentfulColorfulBoxes;
}

const PerkCardWithLink: React.FC<PerkCardWithLinkProps> = ({
  background,
  item: { heading, shortText, linkText = 'Read More', linkHref = '/' },
}) => {
  return (
    <div
      css={{
        flex: '0 1 auto',
        padding: '24px 20px',
        textAlign: 'left',
        borderRadius: 10,
        marginBottom: '1rem',
        backgroundColor: background || '#E2E8F7',
      }}
    >
      {/* Heading */}
      <p
        css={{
          fontFamily: ['Blacker Pro Display'].concat(SYSTEM_FONTS).join(', '),
          fontWeight: 700,
          fontSize: 22,
          color: '#160B2C',
          marginBottom: '0.25rem',
        }}
      >
        {heading}
      </p>
      {/* Text */}
      <p
        css={{
          fontFamily: ['Open Sans'].concat(SYSTEM_FONTS).join(', '),
          fontSize: '0.875rem',
          fontWeight: 400,
          color: hexToRgba('#160B2C', 0.8),
          b: {
            fontWeight: 400,
          },
        }}
      >
        {shortText}
      </p>
      {/* Link */}
      <LinkWithArrow to={linkHref} text={linkText} variant="normal" />
    </div>
  );
};

export default PerkCardWithLink;
