import React from 'react';
import { SYSTEM_FONTS } from '../../styles/typography';
import { Container } from 'emotion-flex';

interface PressNumbersProps {
  image: string;
  heading: string;
  text: string;
}

const PressPerk: React.FC<PressNumbersProps> = ({ image, heading, text }) => {
  return (
    <>
      <Container
        css={{
          alignContent: 'center',
          alignItems: 'center',
        }}
      >
        <img
          alt={heading}
          src={image}
          css={{
            minHeight: 230,
          }}
        />
        <div
          css={{
            fontFamily: ['Blacker Pro Display'].concat(SYSTEM_FONTS).join(', '),
            color: '#160B2C',
            fontSize: 24,
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          {heading}
        </div>
        <div
          css={{
            fontFamily: ['Open Sans'].concat(SYSTEM_FONTS).join(', '),
            color: '#160B2C',
            fontSize: 18,
            textAlign: 'center',
          }}
        >
          {text}
        </div>
      </Container>
    </>
  );
};

export default PressPerk;
